import { makeAutoObservable } from 'mobx';

import CountryCode from '@root/interfaces/CountryCode';

import ImtuPromoClass from '@root/models/ImtuPromoClass';

import { fetchImtuConfig, fetchImtuPromosFromCarriers } from '@services/api/v1/dtcImtuK2';
import { showToast } from '@services/toasts';

class ImtuConfigStore {
  availableCountries: CountryCode[] = [];

  isLoading = false;

  promos: ImtuPromoClass[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchConfig = async () => {
    this.isLoading = true;

    try {
      const [imtuConfigRes, imtuPromosRes] = await Promise.all([
        fetchImtuConfig(),
        fetchImtuPromosFromCarriers(),
      ]);
      this.availableCountries = imtuConfigRes.country_codes;
      // TODO: to be removed when we switch to v2 promos - See ImtuPromosV2Store
      // we're free to remove v1 promos. v2 promos are actually being applied for customer
      this.promos = imtuPromosRes.all_promos.map((item) => {
        return new ImtuPromoClass(item);
      });
    } catch (err) {
      showToast.error(err);
    } finally {
      this.isLoading = false;
    }
  };
}

export default ImtuConfigStore;
