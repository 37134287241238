import React from 'react';
import { useTranslation } from '@nodeModules/react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import { StepModule } from '@root/interfaces/StepModules';
import CountryCode from '@root/interfaces/CountryCode';
import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrCountrySelect from '@components/common/BrCountrySelect';
import BrButton from '@components/common/BrButton';
import BrCard from '@components/common/BrCard';
import BrFastAccessCountries from '@components/common/BrFastAccessCountries';

import ImtuTransaction from '@root/models/ImtuTransaction';

import { getCountryNameByCode } from '@services/countryList';
import { showToast } from '@services/toasts';

import { replaceAccentCharacters } from '@utils/string';

import { getPhoneDialCodeByCountryCode } from '@helpers/phone';

import { CountryAliases } from '@root/interfaces/appConfig';

import Store from './Store';
import { VALIDATION_ERRORS } from './constants';

import CountrySkeleton from './components/CountrySkeleton';
import RecentContactsSection from './components/RecentContactsSection';
import RecentActivitySection from './components/RecentActivitySection';

import findOptionByAlias from './helpers/findOptionByAlias';

interface Props extends StepModule {
  countries: CountryCode[];
  selectedCountryCode?: CountryCode;
  countryAliases?: CountryAliases[];
  recentContactsTxns?: ImtuTransaction[];
  recentTxns?: ImtuTransaction[];
  featuredCountries?: CountryCode[];
  onContactSelect(txnId: string): void;
  onRecentTxnSelect(txnId: string): void;
  onFinish(args: { countryCode: CountryCode }): void;
}

const Country: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    selectedCountryCode,
    countries,
    countryAliases,
    recentContactsTxns,
    recentTxns,
    featuredCountries,
    dataTestPrefix,
    onContactSelect,
    onRecentTxnSelect,
    onFinish,
  } = props;

  const { t } = useTranslation();

  const store = useLocalStore(
    () =>
      new Store({
        countryCode: selectedCountryCode,
      }),
  );

  const handleFinish = () => {
    if (store.countryCode) {
      onFinish({
        countryCode: store.countryCode,
      });
    } else {
      showToast.warning(VALIDATION_ERRORS.COUNTRY_NOT_SELECTED);
    }
  };

  const handleCountrySelect = (value: string | CountryCode) => {
    store.setCountryCode(value as CountryCode); // TODO: revisit select interface to make it's value to be generic
    handleFinish(); // automatically finish the step on country selection
  };

  const handleOnSearch = (option: BrOptionProps, searchQuery: string) => {
    const normalizedQueryString = replaceAccentCharacters(searchQuery.toLowerCase());
    const normalizedSourceString = replaceAccentCharacters(option.text.toLowerCase());
    const isQueryStartsFromDialCode = /^\+?\d*$/.test(searchQuery);
    if (isQueryStartsFromDialCode) {
      return Boolean(option.addonTextLeft?.includes(searchQuery));
    }
    return Boolean(
      normalizedSourceString.includes(normalizedQueryString) ||
        (countryAliases &&
          findOptionByAlias(option, countryAliases, normalizedQueryString)),
    );
  };

  const handleContactSelect = (txnId: string) => {
    onContactSelect(txnId);
  };

  const handleRecentTxnSelect = (txnId: string) => {
    onRecentTxnSelect(txnId);
  };

  return (
    <>
      {countries.length ? (
        <>
          <BrCard className="!overflow-visible">
            <div className="space-y-default">
              <div className="space-y-middle">
                <div className="text-body/primary/demibold first-letter:capitalize">
                  {t('select recipient’s country')}
                </div>
                <BrCountrySelect
                  placeholder={t('Select or type a country')}
                  drawerTitleText={t('Select recipient`s country')}
                  isOpened={!store.countryCode}
                  hasSearch
                  data={countries.map((code) => {
                    return {
                      value: code,
                      text: getCountryNameByCode(code) ?? '',
                      addonTextLeft: getPhoneDialCodeByCountryCode(code),
                    };
                  })}
                  value={store.countryCode || ''}
                  onChange={handleCountrySelect}
                  dropdownMaxHeight={300}
                  dropdownOptionHeight={48}
                  onSearch={handleOnSearch}
                  iconLeft="search"
                  onWhat="surface"
                />
              </div>
              {featuredCountries ? (
                <BrFastAccessCountries
                  data={featuredCountries}
                  onItemClick={handleCountrySelect}
                />
              ) : null}
            </div>
          </BrCard>
          {recentContactsTxns?.length ? (
            <RecentContactsSection
              txns={recentContactsTxns}
              onItemSelect={handleContactSelect}
            />
          ) : null}
          {recentTxns?.length ? (
            <RecentActivitySection
              txns={recentTxns}
              onItemClick={handleRecentTxnSelect}
            />
          ) : null}
        </>
      ) : (
        <CountrySkeleton />
      )}

      <BrBottomControlsWrapper>
        <BrButton
          onClick={handleFinish}
          text={t('Continue')}
          className="w-full"
          dataTestId={`${dataTestPrefix}-next-btn`}
        />
      </BrBottomControlsWrapper>
    </>
  );
};

export default observer(Country);
