import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import { showBrToast } from '@services/toasts';

import { UserAccountStore } from '@root/stores';

import useStore from '@root/hooks/useStore';
import useModal from '@root/hooks/useModal';
import useDidUpdate from '@root/hooks/useDidUpdate';

import PaymentCardClass from '@root/models/PaymentCardClass';

import { StepModule } from '@root/interfaces/StepModules';
import CountryCode from '@root/interfaces/CountryCode';

import { useIsDesktop } from '@components/common/MediaQueryMatchers';

import BrButton from '@components/common/BrButton';
import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrPaymentCardSelect from '@components/common/BrPaymentCardSelect';
import BrModal from '@components/common/BrModal';
import BrDrawer from '@components/common/BrDrawer';
import BrCvvForm from '@components/common/BrCvvForm';
import BrExpiredPaymentCardUpdateUi from '@components/common/BrPaymentCardSelect/BrExpiredPaymentCardUpdateUi';

import {
  PaymentCardFundingType,
  PaymentCardFundingTypeMap,
} from '@root/constants/moneyTransfer/paymentCards';

import { capitalizeFirstLetterInEachWord } from '@utils/string';

import ImtuTermsBlock from '../../components/ImtuTermsBlock';

import Store from './Store';

interface Props extends StepModule {
  selectedCardId?: string;
  amountText?: string;
  isLoading?: boolean;
  userCountryOfOrigin: CountryCode;
  onFinish(args: { paymentInfo: PaymentCardClass; cvvCode: string }): void;
}

const Summary: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    selectedCardId,
    amountText,
    isLoading,
    userCountryOfOrigin,
    dataTestPrefix,
    onFinish,
  } = props;

  const { t } = useTranslation();

  const store = useLocalStore(() => new Store());

  const isDesktop = useIsDesktop();

  const {
    isModalOpen,
    modalHeader,
    modalContent,
    showModal,
    closeModal,
    setModalHeader,
    setModalContent,
  } = useModal();

  const showCvvModal = (card: PaymentCardClass) => {
    setModalHeader(t('Please enter CVV'));
    setModalContent(
      <BrCvvForm
        cardIssuerType={card.paySource}
        cardNumber={card.maskedNumber}
        onSubmit={store.setCvvCode}
      />,
    );
    showModal();
  };

  const handleOnCardAdded = (card: PaymentCardClass, cvv?: string) => {
    if (cvv) {
      onFinish({ paymentInfo: card, cvvCode: cvv });
    } else {
      showCvvModal(card);
    }
  };

  const handleOnCardEdited = (card: PaymentCardClass) => {
    showCvvModal(card);
  };

  const {
    selectProps,
    paymentCardReducedDetailsDialogProps,
    selectedPaymentCard,
    showPaymentCardForm,
    isLoading: isCardsLoading,
  } = BrPaymentCardSelect.useBrPaymentCardSelect({
    handleId: selectedCardId,
    isUsingCardsWithoutBillingAllowed: true,
    userCountryOfOrigin,
    onCardAdded: handleOnCardAdded,
    onCardEdited: handleOnCardEdited,
  });

  const showExpiredPaymentCardModal = (card: PaymentCardClass) => {
    const handleOnAdd = () => {
      showPaymentCardForm();
      selectProps.onCardAdd?.();
      closeModal();
    };

    const handleOnEdit = () => {
      showPaymentCardForm();
      selectProps.onCardEdit?.(card.id);
      closeModal();
    };

    const fundingType = capitalizeFirstLetterInEachWord(
      PaymentCardFundingTypeMap[card.type],
    ) as PaymentCardFundingType;

    setModalHeader(t('Expired payment card'));
    setModalContent(
      <BrExpiredPaymentCardUpdateUi
        maskedNumber={card.maskedNumber}
        onAdd={handleOnAdd}
        onUpdate={handleOnEdit}
        fundingType={fundingType}
      />,
    );
    showModal();
  };

  const handleFinish = () => {
    if (selectedPaymentCard) {
      if (selectedPaymentCard.isExpired) {
        showExpiredPaymentCardModal(selectedPaymentCard);
        return undefined;
      }
      if (!store.cvvCode) {
        showCvvModal(selectedPaymentCard);
      } else {
        onFinish({
          paymentInfo: selectedPaymentCard,
          cvvCode: store.cvvCode,
        });
      }
    } else {
      showBrToast.small(t('Select a card'));
    }

    return undefined;
  };

  const isPaymentCardFormOpened = paymentCardReducedDetailsDialogProps.isOpen;

  useDidUpdate(() => {
    store.setCvvCode('');
  }, [selectedPaymentCard]);

  useDidUpdate(() => {
    if (store.cvvCode) {
      closeModal();
      handleFinish();
    }
  }, [store.cvvCode]);

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const dialog = isDesktop ? (
    <BrModal
      header={modalHeader}
      isOpen={isModalOpen}
      onClose={closeModal}
      hasCloseButton
    >
      {modalContent}
    </BrModal>
  ) : (
    <BrDrawer isOpen={isModalOpen} onClose={closeModal} title={String(modalHeader)}>
      {modalContent}
    </BrDrawer>
  );
  const handleShowTermsModalClick = () => {
    setModalHeader(t('Terms of use'));
    setModalContent(
      <ImtuTermsBlock
        countryCode={userAccountStore.userCountryOfOrigin}
        languageLocale={userAccountStore.currentLanguageLocale}
      />,
    );
    showModal();
  };
  return (
    <>
      {isPaymentCardFormOpened ? (
        <>
          <BrPaymentCardSelect.BrPaymentCardReducedDetailsForm
            {...paymentCardReducedDetailsDialogProps}
            dataTestPrefixId="br-payment-card-form"
            submitBtnText={
              paymentCardReducedDetailsDialogProps.isEdit
                ? t('Update card & complete oder')
                : t('Add card & complete order')
            }
          />
        </>
      ) : (
        <>
          <BrPaymentCardSelect {...selectProps} />
          <div className="pb-xlarge m-auto">
            <BrButton
              onClick={handleShowTermsModalClick}
              cmpType="text"
              size="middle"
              text={t('Terms of use')}
            />
          </div>
          <BrBottomControlsWrapper>
            <BrButton
              disabled={isLoading || isCardsLoading}
              onClick={handleFinish}
              text={t('send {{amount}} top-up', {
                amount: amountText,
              })}
              className="w-full first-letter-uppercase"
              dataTestId={`${dataTestPrefix}-next-btn`}
            />
          </BrBottomControlsWrapper>
        </>
      )}

      {dialog}
    </>
  );
};

export default observer(Summary);
