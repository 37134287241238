import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Lottie from 'lottie-react-web';

import { normalizeStringCompound } from '@utils/string';

import { BrAdvancedInputProps } from '@root/interfaces/components/BrAdvancedInput';

import BrInput from '../BrInput';
import BrButton from '../BrButton';

import successIconAnimation from './loading.json';

const BASIC_CLASS = 'br-input';

export type BrAdvancedInputRef = {
  inputRef: React.RefObject<HTMLInputElement>;
  inputWrapperRef: React.RefObject<HTMLDivElement>;
};

const SUCCESS_ICON_ANIMATION_DELAY = 750;

const BrAdvancedInput = forwardRef(
  (props: BrAdvancedInputProps, ref: ForwardedRef<BrAdvancedInputRef>) => {
    const {
      topLabel,
      bottomLabel,
      errorText,
      rightBtnText,
      actionBtnIcon,
      customRightActionBtnConfig,
      hasError,
      hasSuccess,
      isDisabled,
      onRightBtnClick,
      onActionBtnClick,
      id,
      className,
      required,
      ...restProps
    } = props;

    const animationTimeoutId = useRef<number>();

    const [isSuccessIconVisible, setIsSuccessIconVisible] = useState(false);

    const classNames = normalizeStringCompound([
      hasError ? `${BASIC_CLASS}-hasError` : undefined,
      hasSuccess ? `${BASIC_CLASS}-hasSuccess` : undefined,
    ]);

    useEffect(() => {
      if (hasSuccess) {
        animationTimeoutId.current = window.setTimeout(() => {
          setIsSuccessIconVisible(true);
        }, SUCCESS_ICON_ANIMATION_DELAY);
      }
      return () => {
        if (animationTimeoutId.current) {
          clearTimeout(animationTimeoutId.current);
        }
      };
    }, [hasSuccess]);

    useEffect(() => {
      if (hasError) {
        setIsSuccessIconVisible(false);
      }
    }, [hasError]);

    const inputRef = useRef<HTMLInputElement>(null);
    const inputWrapperRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => {
      return {
        inputRef,
        inputWrapperRef,
      };
    });

    const customRightIcon = hasSuccess && isSuccessIconVisible && (
      <Lottie
        options={{ animationData: successIconAnimation, loop: false }}
        ariaRole="img"
        height={45.5}
        width={48}
      />
    );

    return (
      <div className={normalizeStringCompound(['flex flex-col', className])}>
        {topLabel && (
          <label
            className="inline-flex cursor-pointer text-body/caption-2/default text-support-colors/highlights mb-xsmall"
            htmlFor={id}
          >
            <span className={normalizeStringCompound([required ? 'mr-xxsmall' : ''])}>
              {topLabel}
            </span>
            {required && <span className="text-color/error">*</span>}
          </label>
        )}
        <div className="flex items-center space-x-middle">
          <div className="w-full" ref={inputWrapperRef}>
            <BrInput
              className={classNames}
              {...restProps}
              id={id}
              isDisabled={isDisabled}
              ref={inputRef}
              customRightIcon={customRightIcon}
            />
          </div>
          {rightBtnText && onRightBtnClick && (
            <BrButton
              className="!text-body/caption/default"
              cmpType="link"
              onClick={onRightBtnClick}
              text={rightBtnText}
              hasIconRight
            />
          )}
          {actionBtnIcon && (
            <BrButton
              cmpType="white"
              onClick={onActionBtnClick}
              iconName={actionBtnIcon}
              hasIconRight
            />
          )}
          {customRightActionBtnConfig && (
            <div className="flex items-center rounded-middle bg-white">
              {customRightActionBtnConfig.map((item) => (
                <BrButton
                  key={item.id}
                  cmpType="white"
                  className="flex"
                  onClick={item.onClick}
                  iconName={item.iconName}
                  hasIconRight
                />
              ))}
            </div>
          )}
        </div>
        {bottomLabel && (
          <div className="text-body/caption-2/default text-support-colors/highlights mt-xsmall">
            <label className="inline-flex cursor-pointer" htmlFor={id}>
              {bottomLabel}
            </label>
          </div>
        )}
        {hasError && errorText && (
          <div className="text-body/caption-2/default text-color/error mt-xsmall">
            <span className="inline-flex">{errorText}</span>
          </div>
        )}
      </div>
    );
  },
);

export default BrAdvancedInput;
