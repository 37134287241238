import { ListOnScrollProps } from 'react-window';
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import type SimpleBar from 'simplebar-react';

import { normalizeStringCompound } from '@utils/string';

import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import BrIllustration from '@components/common/BrIllustration';

import BrDropdown from '../BrDropdown';
import VirtualOptionList from './VirtualOptionList';

import useDynamicHeight from './useDynamicHeight';

type CustomItem = {
  option: BrOptionProps;
  isActive: boolean;
  onSelect: (option: BrOptionProps) => void;
};
interface Props {
  searchValue?: string;
  notFoundText?: string;
  className?: string;
  dropdownOpenDirection?: 'up' | 'down';
  dropdownMaxHeight?: number;
  optionHeight: number;
  isOpen?: boolean;
  options: BrOptionProps[];
  style?: React.CSSProperties;
  selectedOption?: BrOptionProps;
  addonBottom?: React.ReactNode;
  onSelect(option: BrOptionProps): void;
  onAnimationEnd?(): void;
  renderItem?(item: CustomItem, itemIdx: number): JSX.Element;
}

const DEFAULT_SELECT_DROPDOWN_MAX_HEIGHT = 248;
const ADDON_BOTTOM_RESERVED_HEIGHT = 86;

const Dropdown = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    searchValue,
    selectedOption,
    options,
    notFoundText,
    isOpen,
    onSelect,
    renderItem,
    addonBottom,
    dropdownMaxHeight = DEFAULT_SELECT_DROPDOWN_MAX_HEIGHT,
    onAnimationEnd,
    optionHeight,
    className,
    dropdownOpenDirection = 'down',
    style,
  } = props;

  const listRef = useRef<React.MutableRefObject<SimpleBar> | null>(null);

  const optionListMaxHeight = addonBottom
    ? dropdownMaxHeight - ADDON_BOTTOM_RESERVED_HEIGHT
    : dropdownMaxHeight;

  const { dynamicHeightValue: listWrapperHeight } = useDynamicHeight({
    listRef,
    maxHeight: optionListMaxHeight,
    deps: [searchValue, isOpen, options.length],
  });

  const listContentHeight: number | undefined =
    // TODO: there is a lib's related type issue, for now I just put ts-ignore
    // @ts-ignore
    listRef.current?.current?.children?.[0].clientHeight;

  const [isAddonBottomTopShadowVisible, setIsAddonBottomTopShadowVisible] = useState(
    false,
  );

  useEffect(() => {
    if (addonBottom && listContentHeight) {
      const isShadowVisible = Boolean(listContentHeight > listWrapperHeight);
      setIsAddonBottomTopShadowVisible(isShadowVisible);
    }
  }, [isOpen, listWrapperHeight, listContentHeight, addonBottom]);

  const handleOnAnimationEnd = () => {
    if (!isOpen) {
      // TODO: there is a lib's related type issue, for now I just put ts-ignore
      // @ts-ignore
      if (listRef.current?.current?.scrollTop) {
        // TODO: there is a lib's related type issue, for now I just put ts-ignore
        // @ts-ignore
        listRef.current.current.scrollTop = 0;
      }
    }
    onAnimationEnd?.();
  };

  const handleOnScroll = (e: ListOnScrollProps) => {
    if (isOpen && listContentHeight) {
      const isScrolledToBottom = Boolean(
        listContentHeight - e.scrollOffset === listWrapperHeight,
      );
      setIsAddonBottomTopShadowVisible(!isScrolledToBottom);
    }
  };

  return (
    <BrDropdown
      isOpen={isOpen}
      ref={ref}
      style={{ maxHeight: dropdownMaxHeight, ...style }}
      onAnimationEnd={handleOnAnimationEnd}
      className={className}
      dropdownOpenDirection={dropdownOpenDirection}
    >
      {/* Do not remove options list otherwise it will break dynamic height calculation because of ref */}
      <VirtualOptionList
        options={options}
        onSelect={onSelect}
        renderItem={renderItem}
        selectedOption={selectedOption}
        dropdownHeight={listWrapperHeight}
        listRef={listRef}
        optionHeight={optionHeight}
        onScroll={handleOnScroll}
      />
      {!options.length && (
        <div className="w-full h-full flex flex-col items-center justify-center p-default gap-small">
          <BrIllustration type="notFound" />
          <div className="text-body/caption/default text-support-colors/highlights">
            {notFoundText}
          </div>
        </div>
      )}
      {addonBottom && (
        <>
          <div
            className={normalizeStringCompound([
              'absolute w-full left-0 bottom-0 h-[86px] rounded-default p-default bg-color/primary transition-shadow duration-50',
              isAddonBottomTopShadowVisible ? 'shadow-Shadows/Apple' : '',
            ])}
          >
            {addonBottom}
          </div>
          <div className="h-[86px]" />
        </>
      )}
    </BrDropdown>
  );
});

export default Dropdown;
