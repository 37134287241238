import { CountryAliases } from '@root/interfaces/appConfig';
import { BrOptionProps } from '@root/interfaces/components/BrSelect';

const escapeRegex = (string: string) => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
};

const findOptionByAlias = (
  option: BrOptionProps,
  aliasArr: CountryAliases[],
  searchString: string,
) => {
  return aliasArr.find(
    ({ value, aliases }) =>
      value.toLowerCase() === option.value.toLowerCase() &&
      aliases.some((alias) =>
        new RegExp(escapeRegex(searchString.toLowerCase())).test(alias.toLowerCase()),
      ),
  );
};

export default findOptionByAlias;
