import { useTranslation } from 'react-i18next';

import BrBadge from '@components/common/BrBadge';
import BrButton from '@components/common/BrButton';
import BrContacts, { OnClickProps } from '@components/common/BrContacts';

import { formatMoneyToText } from '@helpers/money';

import { normalizeStringCompound } from '@utils/string';

import CountryCode from '@root/interfaces/CountryCode';

import { getCountryBackgroundImgSrc } from '@services/cdn';

import defaultCountryImage from '@src/static/assets/images/default-country-img.png';

interface Props {
  phone: string;
  senderAmount: number;
  senderCurrency: string;
  carrierName: string;
  recipientAmount: number;
  recipientCountryCode: CountryCode;
  promoText?: string;
  recipientCurrency: string;
  onClickProps?: OnClickProps;
  hasSeparateLine?: boolean;
}

const RecentTxn: React.FC<Props> = (props) => {
  const {
    phone,
    promoText,
    senderAmount,
    senderCurrency,
    recipientAmount,
    recipientCurrency,
    carrierName,
    recipientCountryCode,
    onClickProps,
    hasSeparateLine,
  } = props;

  const { t } = useTranslation();

  const bottomText = `${carrierName} ${recipientCountryCode} · ${formatMoneyToText(
    recipientAmount,
    recipientCurrency,
  )}`;

  const handleOnClick = () => {
    onClickProps?.onClick(onClickProps.id);
  };

  return (
    <div
      className={normalizeStringCompound([
        'flex items-center space-x-middle bg-color/surface',
        hasSeparateLine ? 'pb-middle border-b border-support-colors/lines' : '',
      ])}
    >
      <BrContacts
        cmpSize="small"
        cmpType="person"
        promoText={promoText ? t('Promo') : undefined}
        imageUrl={getCountryBackgroundImgSrc(recipientCountryCode)}
        fallbackImageUrl={defaultCountryImage}
      />
      <div className="space-y-xsmall !mr-auto">
        <div className="text-body/caption/medium">{phone}</div>
        <div className="flex items-center space-x-xsmall">
          <div className="text-body/caption-2/demibold">
            {formatMoneyToText(senderAmount, senderCurrency)}
          </div>
          {promoText && (
            <BrBadge
              classType="warning"
              iconLeft="gift"
              text={promoText}
              styleType="default"
            />
          )}
        </div>
        <div className="text-body/caption-2/demibold text-color/black/60">
          {bottomText}
        </div>
      </div>
      <BrButton
        cmpStyle="circle"
        size="default"
        type="button"
        cmpType="gray"
        hasIconLeft
        iconName="send-top-up-outline"
        className="!text-color/secondary"
        onClick={handleOnClick}
      />
    </div>
  );
};

export default RecentTxn;
