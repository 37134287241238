import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getSortByKeyFn } from '@utils/array';
import { normalizeStringCompound } from '@utils/string';

import BrSelectProps, { BrOptionProps } from '@root/interfaces/components/BrSelect';
import CountryCode from '@root/interfaces/CountryCode';

import { getPhoneDialCodeByCountryCode } from '@helpers/phone';

import BrSelect from '../BrSelect';
import Flag from '../Flag';
import Icon from '../Icon';

export type CountryItem = {
  code: CountryCode;
  value: string;
  textLabel: string;
};

interface Props extends Omit<BrSelectProps, 'options' | 'disabled' | 'setForcedOpen'> {
  data: BrOptionProps[];
  onChange?(value: string): void;
}

interface Props extends Omit<BrSelectProps, 'options'> {
  value: string;
  onSearch?(option: BrOptionProps, searchQuery: string): boolean;
}

const BrCountrySelect: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    data,
    value,
    placeholder,
    isOpened,
    isDisabled,
    onChange,
    onSearch,
    drawerTitleText,
    dropdownOptionHeight,
    onWhat,
    ...rest
  } = props;

  const [countryCode, setCountryCode] = useState(value);

  const selectButtonAddonBefore = countryCode && (
    <Flag
      className="w-[16px] h-[16px] overflow-hidden rounded-full flex-shrink-0 mx-middle self-center"
      code={countryCode as CountryCode}
    />
  );

  const { t } = useTranslation();

  const countryOptions: BrOptionProps[] = data
    .sort(getSortByKeyFn('text'))
    .map((item) => {
      return {
        value: item.value,
        text: item.text,
        addonTextLeft: `+${item.addonTextLeft}`,
        label: (
          <div className="flex items-center w-full">
            <Flag
              className="mr-small h-[20px] w-[20px] overflow-hidden rounded-full"
              code={item.value as CountryCode}
            />
            <span
              className={normalizeStringCompound([
                'leading-none',
                item.value === countryCode ? 'text-on-colors/on-secondary-variant' : '',
              ])}
            >
              {t(item.text)}
            </span>
            {item.addonTextLeft && (
              <>
                <div className="ml-auto">{`+${getPhoneDialCodeByCountryCode(
                  item.value as CountryCode,
                )}`}</div>

                <div className="w-large h-large rounded-full bg-transparent flex items-center justify-center p-[3px]">
                  {countryCode === item.value && (
                    <Icon
                      width={20}
                      height={20}
                      name="checkmark-circle"
                      className="bg-transparent text-on-colors/on-secondary-variant"
                    />
                  )}
                </div>
              </>
            )}
          </div>
        ),
      };
    });

  const handleOnChange = (newValue: string) => {
    setCountryCode(newValue);
    onChange?.(newValue);
  };

  return (
    <div>
      <BrSelect
        selectButtonAddonBefore={selectButtonAddonBefore}
        options={countryOptions}
        onChange={handleOnChange}
        value={countryCode}
        placeholder={placeholder}
        isOpened={isOpened}
        isDisabled={isDisabled}
        drawerTitleText={drawerTitleText}
        onSearch={onSearch}
        dropdownOptionHeight={dropdownOptionHeight}
        onWhat={onWhat}
        {...rest}
      />
    </div>
  );
};

export default BrCountrySelect;
